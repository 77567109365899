import { AnalyticsSummaryType } from "types/analytics";

const colors = ["#4d8", "#2cb", "#0bc", "#e94"];
interface Props {
  summary: AnalyticsSummaryType | undefined,
}

const SummaryItem = ({
  title,
  description,
  value,
  unit,
  color,
}: {
  title: string;
  description?: string;
  value: number | undefined;
  unit: string;
  color: string;
}) => (
  <article className="summary-wrapper">
    <hgroup>
      <h3 className="summary-text">{title}</h3>
      {description && (
        <p className="summary-text summary-desc">{description}</p>
      )}
    </hgroup>
    <p className="summary-text summary-val">
      <span style={{ color }}>{`${unit} ${value ?? "N/A"}`}</span>
    </p>
  </article>
);

const Summary = ({ summary }: Props) => {
  const summaryArray = [
    {
      title: "Visitas a la página",
      description: "Usuarios jugando",
      value: summary?.eventCount['front-page']?.['page-load']?.count,
      unit: "",
    },
    {
      title: "Net-Win",
      description: "Profit total",
      value: summary?.netwin,
      unit: "$"
    },
    {
      title: "Balance",
      description: "Total de fichas",
      value: summary?.balance,
      unit: "$"
    },
    {
      title: "Usuarios",
      description: "Usuarios creados",
      value: summary?.eventCount['front-page']?.['new-player']?.count,
      unit: ""
    },
  ]
  
  return (
    <div className="grid" style={{ marginBottom: 20 }}>
      {summaryArray?.map( (item, index) => (
        <SummaryItem  
          key={item.title}
          title={item.title}
          description={item.description}
          value={item.value}
          unit={item.unit}
          color={colors[index % colors.length]}
        />
      ))}
    </div>
  )
}

export default Summary;