import { BankSelectProps } from '../../../types/bankSelect'
import { BankList } from './BankList';

export function BankSelect({
  name,
  value,
  onChange,
  readOnly
}: BankSelectProps) {
  return (
    <select name={name} value={value} onChange={onChange} disabled={readOnly}>
      <option disabled selected value="">
        Banco
      </option>
      {BankList.map((bank) => (
        <option
          key={bank.value}
          value={bank.value}
          disabled={bank.value === ''}
        >
          {bank.name}
        </option>
      ))}
    </select>
  );
}